import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { convertNestedDateToString } from '@depot/@common';
import { IDealerReturnSearch } from '@depot/@data';
import {
  IDealerReturn,
  IDealerReturnPalletImage,
  IDealerReturnScrap,
  IDealerReturnScrapOptions,
  IPagedResults
} from '@depot/custom';
import { environment } from '@env';

import { compare, Operation } from 'fast-json-patch';
import { catchError, Observable, shareReplay, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DealerReturnRepositoryService {
  private scrapOptionsCache$: Observable<IDealerReturnScrapOptions>;
  constructor(private httpClient: HttpClient) { }

  public searchDealerReturns(filters: IDealerReturnSearch) {
    const url = environment.get_endpoint(`dealerReturn`);
    return this.httpClient.get<IPagedResults<IDealerReturn>>(url, { params: <any>filters });

  }

  public getDealerReturn(id: number) {
    const url = environment.get_endpoint(`dealerReturn/${id}`);
    return this.httpClient.get<IDealerReturn>(url);
  }

  public getScrapCommentOptions() {
    if (this.scrapOptionsCache$) {
      return this.scrapOptionsCache$;
    }
    const url = environment.get_endpoint('dealerReturn/scrapcommentoptions');

    this.scrapOptionsCache$ = this.httpClient.get<IDealerReturnScrapOptions>(url).pipe(
      shareReplay(1),
      catchError((err) => {
        this.scrapOptionsCache$ = null;
        return throwError(() => err);
      })
    );
    return this.scrapOptionsCache$;
  }

  public getDealerReturnScrapByPutAwayReportId(putAwayReportId: number) {
    const url = environment.get_endpoint(`dealerReturn/GetDealerReturnScrapByPutAwayReportId/${putAwayReportId}`);
    return this.httpClient.get<IDealerReturnScrap>(url);
  }

  public saveDealerReturnScrap(scrapRow: IDealerReturnScrap, originalScrapRow: IDealerReturnScrap = null) {
    const url = environment.get_endpoint(`dealerReturn/dealerReturnScrap/${scrapRow.id}`);
    const patch = compare(originalScrapRow, scrapRow);
    if (patch.every(x => x.path.toLowerCase().includes('rowversion'))) {
      patch.splice(0);
    }
    return this.httpClient.patch<IDealerReturnScrap>(url, patch);
  }

  public saveDealerReturn(dealerReturn: Partial<IDealerReturn>, originalDealerReturn: Partial<IDealerReturn> = null) {
    convertNestedDateToString(dealerReturn);
    convertNestedDateToString(originalDealerReturn);

    const patch = compare(originalDealerReturn, dealerReturn);
    if (patch.every(x => x.path.toLowerCase().includes('rowversion'))) {
      patch.splice(0);
    }

    const url = environment.get_endpoint(`dealerReturn/${dealerReturn.id}`);
    return this.httpClient.patch<IDealerReturn>(url, patch);

  }

  public deleteDealerReturnAndChildren(dealerReturnId: number) {
    const url = environment.get_endpoint(`dealerReturn/${dealerReturnId}`);

    return this.httpClient.delete(url);
  }

  public softDeleteDealerReturn(dealerReturnId: number, update: Operation[]) {
    const url = environment.get_endpoint(`dealerReturn/softDelete/${dealerReturnId}`);

    return this.httpClient.patch(url, update);
  }

  public saveDealerReturnPalletImage(image: IDealerReturnPalletImage) {
    const url = environment.get_endpoint(`dealerReturn/PalletImage/${image.id}`);

    return this.httpClient.put<IDealerReturnPalletImage>(url, image);
  }

  public deleteDealerReturnPalletImage(dealerReturnImageId: number) {
    const url = environment.get_endpoint(`dealerReturn/PalletImage/${dealerReturnImageId}`);

    return this.httpClient.delete(url);
  }

  public getCarriers() {
    const url = environment.get_endpoint(`dealerReturn/GetCarriers`);
    return this.httpClient.get<string[]>(url);
  }

}
